/*
 *  Document   : _inbox.scss
 *  Author     : RedStar Template
 *  Description: This scss file for email page style classes
 */
#my-div-id {
  background-color: red;
}

.leaflet-control-layers-overlays label span {
  display: flex;
  align-items: center;
}

.leaflet-control-layers-selector {
  width: 2em;
  height: 2em;
  margin-right: 1em;
  margin-top: 5px;
  margin-bottom: 5px;
  accent-color: #a77935;
}

.mapInfo {
  font-size: 18px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.paisatges-comarques-municipis,
.tipus-construccions-div {
  display: flex;
}

.paisatges-comarques-municipis .layersCollapseButton {
  border: none;
  display: flex;
  align-items: center;
  border-radius: 9px;
  background: white;
  font-weight: bold;
  padding: 6px;
  &:hover {
    color: #cb984c;
  }
}

.paisatges-comarques-municipis .leaflet-control-layers-overlays {
  display: flex;
  .mapInfo {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .mapInfo {
    font-size: 14px;
  }
  .leaflet-control-layers-selector {
    width: 1em;
    height: 1em;
    margin-right: 1em;
  }
}

.markerBarraca {
  background-color: rgb(255, 220, 78) !important;
}
.markerAigua {
  background-color: rgb(168, 208, 218) !important;
}
.markerForn {
  background-color: rgb(220, 156, 74) !important;
}
.markerMurs {
  background-color: rgb(194, 181, 172) !important;
}
.markerAltres {
  background-color: rgb(91, 41, 0) !important;
  color: white;
}

.market-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.market-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.market-cluster span {
  line-height: 30px;
}

.leaflet-popup-content {
  min-width: 700px;
  width: auto !important;
}

.sistemaDiv {
  background-color: white;
  font-size: 18px;
  padding: 6px 10px 6px 6px;
  border-radius: 6px;
}

.custom-select {
  display: flex;
  span {
    margin-right: 10px;
    margin-left: 5px;
  }
  select {
    margin-right: 10px;
  }

  .latlngInfo {
    min-width: 80px;
    display: flex;
    justify-content: flex-end;
  }

  #sysRef {
    min-width: 250px;
    display: flex;
    justify-content: center;
  }
}

.searchButton {
  min-width: 160px;
  display: flex;
  justify-content: center;
  outline: 0;
  cursor: pointer !important;
  border: 0px solid #000;
  border-radius: 5px;
  color: #000;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 12px 20px;
  text-align: center;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  :hover {
    color: #cb984c !important;
  }
}

.layersCollapseButton {
  min-width: 159px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
}

.searchButton,
.paisatges-comarques-municipis {
  padding: 5px 10px;
}

.paisatges-comarques-municipis {
  padding: 0 !important;
}

.paisatges-comarques-municipis button {
  padding: 5px 10px;
  height: auto;
}
.paisatges-comarques-municipis .leaflet-control-layers-overlays {
  margin-left: 10px;
}
.cercarDiv {
  display: flex;
}

// Popup things
.popupTitle {
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.popupImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  img {
    max-height: 105px;
    margin: 5px;
  }
}

// Mapes tematics popup
.mapesTematicsPopup {
  max-width: 300px;
}

.mapesTematicsPopupInline {
  font-size: 14px;
  overflow-wrap: break-word;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  h2 {
    color: #916938;
    font-size: 16px;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    margin: 0;
  }
  span {
    margin-top: 5px;
  }
}

.fitxaContent {
  margin-bottom: 20px;
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .col {
    width: 50%;
    .row {
      display: flex;
      font-weight: bold;
      padding-bottom: 5px;
      .popupInfo {
        font-weight: normal;
      }
    }
  }
}

/** CSS FROM ADD-CONSTRUCTION (CHECKBOX VALUES) */

.tipus-div {
  * {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    --transition: 0.15s;
    --border-radius: 0.5rem;
    --background: #cb984c;
    --box-shadow: #cb984c;
    // --box-shadow: #0082ff;
  }

  .cont-bg {
    min-height: 100vh;
    background-image: radial-gradient(
      circle farthest-corner at 7.2% 13.6%,
      rgba(37, 249, 245, 1) 0%,
      rgba(8, 70, 218, 1) 90%
    );
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cont-main {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .cont-checkbox {
    width: 150px;
    height: 100px;
    border-radius: var(--border-radius);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: transform var(--transition);
    background: white;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
    &:active {
      transform: scale(0.9);
    }
    input {
      display: none;
      &:checked + label {
        opacity: 1;
        box-shadow: 0 0 0 3px var(--background);
        .cover-checkbox {
          opacity: 1;
          transform: scale(1);
          svg {
            stroke-dashoffset: 0;
          }
        }
        img {
          -webkit-filter: none; /* Safari 6.0 - 9.0 */
          filter: none;
        }
      }
    }
    label {
      cursor: pointer;
      border-radius: var(--border-radius);
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
      opacity: 0.6;
      padding: 5px;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   border-radius: var(--border-radius);
      //   background: linear-gradient(to bottom right, #fff, rgba(#006baf, 0.2));
      //   mix-blend-mode: overlay;
      //   opacity: 1;
      // }
      img {
        width: 100%;
        height: 70%;
        object-fit: cover;
        /*clip-path: polygon(0% 0%, 100% 0, 100% 81%, 50% 100%, 0 81%);*/
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
      .cover-checkbox {
        position: absolute;
        right: 5px;
        top: 3px;
        z-index: 1;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: var(--box-shadow);
        border: 2px solid #fff;
        transition: transform var(--transition),
          opacity calc(var(--transition) * 1.2) linear;
        opacity: 0;
        transform: scale(0);
        svg {
          width: 13px;
          height: 11px;
          display: inline-block;
          vertical-align: top;
          fill: none;
          margin: 5px 0 0 3px;
          stroke: #fff;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 16px;
          transition: stroke-dashoffset 0.4s ease var(--transition);
          stroke-dashoffset: 16px;
        }
      }
      .info {
        text-align: center;
        margin-top: 0.2rem;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
}
